import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 Not found" />
    <div
      style={{
        display: "flex",
        height: "100vh",
        background: "linear-gradient(42.18deg, #ef473a 24.59%, #cb2d3e 81.66%)",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "#fff",
        paddingTop: "60px",
        textAlign: "center",
      }}
    >
      <h1>404 Not Found</h1>
      <p>
        You just hit a route that doesn't exist.
        <br />
        <Link to="/" style={{ color: "#000" }}>
          Back to Home
        </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
